@import "../../styles/_chunk";

.fanatical-splash-header {
  background-repeat: no-repeat;
  background-position: center !important;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 320px;
  }

  @include media-breakpoint-up(lg) {
    height: 400px;
  }

  @include media-breakpoint-up(xl) {
    height: 450px;
  }

  &::before {
    border-left: 100vw solid transparent;
    border-right: 50vw solid transparent;
    border-bottom: 8vw solid $about-bg-color;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    content: '';
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 50vw solid $orange-100;
    border-top: 4.1vw solid transparent;
    border-bottom: 4vw solid transparent;
    z-index: 3;
    content: '';
  }

  .header-area {
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }

    h1 {
      margin: 0;
      padding: 0.6rem 0;

      @include media-breakpoint-up(md) {
        padding: 0.8rem 0;
      }

      @include media-breakpoint-up(lg) {
        padding: 1rem 0;
      }

      span {
        background-color: rgba($primary-core, 0.9);
        padding: 0.6rem 1.6rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          padding: 0.8rem 2rem;
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(lg) {
          padding: 1rem 2.4rem;
          font-size: 2.25rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 2.5rem;
        }
      }
    }
  }

  .subheader-area {
    p {
      margin: 0;
      padding: 0.3rem 0;

      @include media-breakpoint-up(md) {
        padding: 0.5rem 0;
      }

      span {
        background-color: rgba($grey-13, 0.9);
        color: $text-white;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: bold;

        @include media-breakpoint-up(md) {
          padding: 0.5rem 2rem;
          font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
          padding: 0.5rem 1.8rem;
          font-size: 1.25rem;
        }
      }
    }
  }
}
